import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft, faPerson } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { AppContext } from '../contexts/AppContext'
import { EntryContext, contextConfig as entryContextConfig } from '../contexts/EntryContext'
import { PersonContext, contextConfig as personContextConfig } from '../contexts/PersonContext'
import InfiniteScroll, { infiniteScrollConfig } from '../common/InfiniteScroll'
import { AppContextState } from '../contexts/AppContext.d'
import Tabs, { TabSet } from '../common/Tabs'
library.add(faPerson, faAlignLeft)

export type SearchDomain = 'entry' | 'person'

const getSearchDomain = (appState: AppContextState): SearchDomain => appState.search?.domain === 'person' ? 'person' : 'entry'

const searchTabs: TabSet<SearchDomain> = {
	'entry': {
		icon: 'align-left',
		text: 'Search entries'
	},
	'person': {
		icon: 'person',
		text: 'Search people'
	}
}

const SearchTabs = (): JSX.Element => {
	const { appState, appDispatch } = useContext(AppContext)
	return <Tabs
		tabs={searchTabs}
		currentTab={getSearchDomain(appState)}
		changeTab={(domain) => appDispatch({ type: 'SEARCH_SELECT_DOMAIN', domain })}  />
}

const SearchTermBox = (): JSX.Element => {
	const { appState, appDispatch, setShortcutsActive } = useContext(AppContext)
	return <div className='search-query'>
		<label htmlFor='search-term'><FontAwesomeIcon icon='magnifying-glass'/></label>
		<input id='search-term' value={appState.search?.term || ''}
			onChange={(e) => appDispatch({ type: 'SEARCH_SET_TERM', term: e.target.value })}
			onFocus={() => setShortcutsActive(false)}
			onBlur={() => setShortcutsActive(true)} />
		{' '}
		<div className='separator' />
		<input id='search-case-sensitive' type='checkbox' checked={appState.search?.caseSensitive || false}
			onChange={() => appDispatch({ type: 'SEARCH_SET_CASE_SENSITIVE', sensitive: !appState.search?.caseSensitive })} />
		<label htmlFor='search-case-sensitive'>Case sensitive</label>
	</div>
}

const SearchResults = (): JSX.Element => {
	const { appState } = useContext(AppContext)
	const entryContext = useContext(EntryContext)
	const personContext = useContext(PersonContext)
	if (!appState.search?.term || appState.search.term.length < 3) {
		return <></>
	}
	return <InfiniteScroll key='search-results' {...infiniteScrollConfig(
		{
			...getSearchDomain(appState) === 'entry' ? entryContextConfig : personContextConfig,
			renderDetails: undefined
		},
		getSearchDomain(appState) === 'entry' ? entryContext.state : personContext.state,
		{
			field: 'search',
			comparison: 'contains',
			value: appState.search.term,
			caseSensitive: appState.search.caseSensitive
		})} />
}

const SearchPage = (): JSX.Element => {
	return <>
		<SearchTabs/>
		<SearchTermBox />
		<SearchResults />
	</>
}

export default SearchPage
