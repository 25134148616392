import React, { useContext } from 'react'
import { actionPending, actionQueryCompleted, conditionalDispatch } from '../helpers/action/action'
import { DEFAULT_ITEMS_PER_PAGE, LISTEN_TO_WINDOW, MIN_ITEMS_VISIBLE } from './infinitescroll/config'
import { infiniteScrollConfig as infiniteScrollConfigOrig } from './infinitescroll/helpers'
import { InfiniteScrollContextProvided, InfiniteScrollHeaderProps, InfiniteScrollProps } from './InfiniteScroll.d'
import { LoadDetailsAction, PrimaryAction } from '../helpers/action/Action.d'
import ReactInfiniteScroller from 'react-infinite-scroller'
import { EmptySearchError } from '../common/ErrorMessage'
import PropTypes from 'prop-types'
import Loading from './Loading'
import { formatDate } from '../helpers/format'
import { PersonContext } from '../contexts/PersonContext'

export const infiniteScrollConfig = infiniteScrollConfigOrig

export const InfiniteScrollHeader = (props: InfiniteScrollHeaderProps): JSX.Element => {
	const { state: people } = useContext(PersonContext)
	if (!props.value) {
		return <h3>Sorted by <em>{props.field}</em>:</h3>
	} else if (props.field === 'person') {
		return <h3>{props.personLinkText} <em>{people.people?.[Number(props.value)]?.name || 'person #' + props.value}</em>:</h3>
	} else if (props.field === 'date' && props.comparison === 'after') {
		return <h3>Most mentioned since <em>{formatDate(props.value, 'long')}</em>:</h3>
	} else {
		return <h3>With <em>{props.field?.replaceAll('_', ' ')}</em> {props.comparison || 'of'} <em>{props.value}</em>:</h3>
	}
}

/**
 * This component does all the legwork of outputting unlimited lists of 'items',
 *  including dealing with scrolling down, sending API requests, retrieving data
 *  from context state and stopping when no more data are available.
 */
const InfiniteScroll = (props: InfiniteScrollProps): JSX.Element => {
	// Read from the provided context.
	const { state, dispatch } = useContext<InfiniteScrollContextProvided>(props.context)
	const itemsPerPage = props.itemsPerPage || DEFAULT_ITEMS_PER_PAGE

	// Track how many items are currently visible in the scroll
	const [currentViewLength, setCurrentViewLength] = React.useState<number>(Math.max(itemsPerPage, MIN_ITEMS_VISIBLE))
	// Make sure to prevent duplicate requests being sent through race conditions while we wait
	const [paused, setPaused] = React.useState<boolean>(false)

	if (props.maxItems && currentViewLength > props.maxItems) {
		setCurrentViewLength(props.maxItems)
		return <></>
	}

	/** Dispatch an action only if it isn't currently in progress */
	const dispatchSafely = (action: PrimaryAction, key: string | PrimaryAction = JSON.stringify(action)) => {
		if (paused || actionPending(key, state) || actionQueryCompleted(key, state))
			return
		setPaused(true)   // prevents other actions on future renders before the timer has fired
		setTimeout(() => {
			conditionalDispatch(action, state, dispatch, { key })
			setPaused(false)
		}, 1)
	}

	const produceIdsKey = JSON.stringify(props.produceIds)
	const knownIds = state.idsByQuery?.[produceIdsKey] || []
	const idsReadyToRender = knownIds.filter(id => props.itemCanBeRendered(id))
	const idsRenderedNow = idsReadyToRender.slice(0, currentViewLength)

	// Retrieve any IDs which are either missing or potentially out-of-date
	if (!actionQueryCompleted(produceIdsKey, state)) {
		// If we refreshed the page, start retrieving IDs from the beginning again in case they changed
		if (state.actionStatus?.[produceIdsKey] === undefined) {
			dispatchSafely({ ...props.produceIds, offset: 0 }, produceIdsKey)
		} else {
			// If we've already retrieved some IDs, retrieve more if/when necessary
			if (currentViewLength - knownIds.length > 0) {
				dispatchSafely({ ...props.produceIds, offset: knownIds.length }, produceIdsKey)
			}
		}
	}

	// Helper functions for retrieving item details
	const detailsAction = (pageStartIndex: number): LoadDetailsAction => ({
		type: props.requestDetailsAction,
		items: knownIds.slice(pageStartIndex, pageStartIndex + itemsPerPage)
	})
	const detailsActionComplete = (pageStartIndex: number): boolean => actionQueryCompleted(detailsAction(pageStartIndex), state)

	/** The start index of the latest chunk of details which haven't yet been retrieved since page load */
	let latestPageStartIndex = currentViewLength - itemsPerPage
		+ (currentViewLength % itemsPerPage === 0 ? 0 : itemsPerPage - currentViewLength % itemsPerPage)
	// Retrieve any missing / out-of-date details, in chunks of size itemsPerPage
	if (detailsAction(latestPageStartIndex).items.length > 0 && !detailsActionComplete(latestPageStartIndex)) {
		// It's possible to reload the page while offline, then scroll down to increase currentViewLength, then go online.
		// In this case, we want to redo all the detailsActions from the start: count backward to find the next one to run.
		while (latestPageStartIndex > 0 && !detailsActionComplete(latestPageStartIndex - itemsPerPage)) {
			latestPageStartIndex -= itemsPerPage
		}
		dispatchSafely(detailsAction(latestPageStartIndex))
	}

	/** Function called when scrolling down, if hasMore is true */
	const loadMore = () => {
		if (knownIds.length > currentViewLength || (knownIds.length <= currentViewLength && !actionQueryCompleted(produceIdsKey, state))) {
			const newViewLength = Math.min(
				idsRenderedNow.length + itemsPerPage, // NB isRenderedNow is capped at currentViewLength
				props.maxItems || Number.POSITIVE_INFINITY)
			if (newViewLength > currentViewLength) {
				setCurrentViewLength(newViewLength)
			}
		}
	}

	const alreadyShowingMaxItems = props.maxItems !== undefined
		&& idsRenderedNow.length >= props.maxItems

	/** if hasMore, the Loading bar will be shown and scroll events will be listened for */
	const hasMore = !alreadyShowingMaxItems &&
			// Either more IDs could be retrieved, or more not-yet-shown details are known about
			(!actionQueryCompleted(produceIdsKey, state) || currentViewLength < knownIds.length)

	return <>
		{ idsRenderedNow.length && props.optionalTitle || false }
		<ReactInfiniteScroller
			initialLoad={false}
			hasMore={hasMore}
			loadMore={loadMore}
			useWindow={LISTEN_TO_WINDOW}
			loader={<Loading key='Loading' />}>
			{knownIds.length === 0 && actionQueryCompleted(props.produceIds, state)
				? !props.optionalTitle && <EmptySearchError message={props.errorMessage} />
				: props.renderOneItemOnly && idsRenderedNow.length === 1
					? props.renderOneItemOnly(idsRenderedNow[0])
					: idsRenderedNow.map(id => props.renderItem(id))}
		</ReactInfiniteScroller>
	</>
}

InfiniteScroll.propTypes = {
	context: PropTypes.object.isRequired,
	/** Takes an item ID and returns whether enough data is present to render it */
	itemCanBeRendered: PropTypes.func.isRequired,
	renderItem: PropTypes.func.isRequired,
	/** If a query has strictly one response, it may be output differently */
	renderOneItemOnly: PropTypes.func,
	/** Action to dispatch to request a specific set of items by ID */
	requestDetailsAction: PropTypes.string.isRequired,
	/** Either an action to query for IDs, or a function to generate those IDs */
	produceIds: PropTypes.shape({
		type: PropTypes.string.isRequired,
		offset: PropTypes.number // default: 0
	}),
	/** This can be any value up to the number returned per API call */
	itemsPerPage: PropTypes.number,
	/** If a query fails and there's no data to output, this error will be shown */
	errorMessage: PropTypes.string,
	/** No scrolling allowed beyond this number of items displayed */
	maxItems: PropTypes.number,
	optionalTitle: PropTypes.object
}

export default InfiniteScroll
