import React, { useContext } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import Graph from '../common/Graph'
import PersonMetadata from './PersonMetadata'
import PersonLink from './PersonLink'
import { PersonContext } from '../contexts/PersonContext'
import { PersonRetriever } from '../reducers/PersonReducer'
import { AppContext } from '../contexts/AppContext'
import { Link } from 'react-router-dom'

const PersonContent = (props: InferProps<typeof personContentPropTypes>): JSX.Element => {
	const { state } = useContext(PersonContext)
	const personId = typeof props.personId === 'string' ? Number.parseInt(props.personId) : props.personId
	const person = state.people?.[personId]

	// NB this component should always be wrapped by a PersonRetriever or
	//  InfiniteScroll, so it should be safe to assume all needed state is present.
	if (!person)
		return <></>

	const groupMates = renderGroupMates(person.group_mates)
	const baseNames = person.base_name?.split('; ')

	return (
		<div className='person-content'>
			<div className='thumbnail thumbnail-static person-thumbnail'>
				<h3>{person.name}
					{person.country && <span className='person-country'> {person.country}</span>}
				</h3>
				<PersonMetadata person={person} />
			</div>

			<BaseNamesLists baseNames={baseNames} name={person.name} />

			{ !!groupMates.length &&
					<div className='thumbnail thumbnail-static person-thumbnail'>
						<div className='person-group-mates'>
							Knows {groupMates}.
						</div>
					</div>}

			{ person.entries && person.entries > 1 && <PersonRetriever>
				<Graph context={PersonContext} action={{ type: 'GRAPH_PEOPLE_FOR_PERSON', person: Number(props.personId) }}/>
			</PersonRetriever> }

			{ person.entries && person.entries > 1 && <PersonRetriever>
				<Graph context={PersonContext} action={{ type: 'GRAPH_PEOPLE_OVER_TIME', people: [Number(props.personId)] }}/>
			</PersonRetriever> }
		</div>
	)
}

const personContentPropTypes = {
	personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}
PersonContent.propTypes = personContentPropTypes

const renderGroupMates = (groupMateField: string | undefined): (string | JSX.Element)[] => {
	// Process group_mates for output.
	const groupMateIds = !groupMateField
		? [] : groupMateField.split('; ')

	const groupMates = groupMateIds.map((personId: string) => [
		<PersonRetriever key={personId}>
			<PersonLink personId={personId} />
		</PersonRetriever>, ', ']).flat()
	groupMates.pop()	// Drop the last comma.
	if (groupMates.length) { groupMates[groupMates.length - 2] = ' and ' }
	return groupMates
}

const BaseNamesLists = (props: { baseNames: string[] | undefined, name: string }): JSX.Element => {
	if (!props.baseNames || props.baseNames.length <= 1) {
		return <></>
	}
	const baseNamesUnique = props.baseNames.filter(n => !n.startsWith('*'))
	const baseNamesMultiple = props.baseNames.filter(n => n.startsWith('*')).map(n => n.substring(1))
	return <div className='thumbnail thumbnail-static thumbnail-base-names'>
		<h3>Found within existing entries</h3>
		{!!baseNamesUnique.length && <>
			The following <em>always</em> matches {props.name}:
			<ul>
				{baseNamesUnique.map(name => <BaseNameLink name={name} key={name} />)}
			</ul>
			<div className='clear-float' />
		</>}
		{!!baseNamesMultiple.length && <>
			The following <em>sometimes</em> matches {props.name}:
			<ul>
				{baseNamesMultiple.map(name => <BaseNameLink name={name} key={name} />)}
			</ul>
			<div className='clear-float' />
		</>}
	</div>
}

const BaseNameLink = (props: { name: string }): JSX.Element => {
	const { appDispatch } = useContext(AppContext)
	const onClick = () => {
		appDispatch({ type: 'SEARCH_SET_TERM', term: props.name })
		appDispatch({ type: 'SEARCH_SET_CASE_SENSITIVE', sensitive: true })
		appDispatch({ type: 'SEARCH_SELECT_DOMAIN', domain: 'entry' })
	}
	return <li><Link onClick={onClick} to='/search'>{props.name}</Link></li>
}

export default PersonContent
