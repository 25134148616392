import React from 'react'
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { faArrowsLeftRightToLine, faCheck, faFlag, faHashtag, faLink, faPersonRays, faPersonWalkingArrowLoopLeft, faSignature,
	faStrikethrough, faUserTag, faWandMagicSparkles, faXmark, faScrewdriverWrench, faSpellCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, PrimaryAction } from '../helpers/action/Action.d'
library.add(faArrowsLeftRightToLine, faCheck, faFlag, faHashtag, faLink, faPersonRays, faPersonWalkingArrowLoopLeft, faSignature,
	faStrikethrough, faUserTag, faWandMagicSparkles, faXmark, faScrewdriverWrench, faSpellCheck)

export type HelpSectionExampleRow = {
	from: string
	to?: string
	date?: string
	description?: string
}

export type HelpSection = {
	text:  string | JSX.Element | ((appDispatch: Dispatch<PrimaryAction>) => JSX.Element)
	title: string
	icon?: IconProp
	subsections?: string[]
	toplevel?: true
	exampleConfig?: {
		isToResult?: boolean
	}
	example?: HelpSectionExampleRow[]
}

export const HELP_SECTIONS: Record<string, HelpSection> = {

	/** WORDS **/
	words: {
		icon: 'spell-check',
		title: 'Words',
		text: (appDispatch) => <>
			<p>The simplest analysis Trove provides is on the words you write.</p>
			<p>
				Once you have a few entries, you’ll be able to view wordclouds of the words
				used particularly often in the recent past (default: the last month) on the{' '}
				<span className='clickable' onClick={() => appDispatch({ type: 'EXPLORE_SELECT_TAB', tab: 'recent' })}>
					<FontAwesomeIcon  icon='clock-rotate-left' />{' '} Recent
				</span> tab.
			</p>
			<p>Note that this will show words used <em>disproportionally often</em> during this time,
				relative to the norm across all entries:</p>
		</>,
		toplevel: true,
		exampleConfig: {
			isToResult: true
		},
		example: [
			{ from: 'Entry text', date: 'How often used', to: 'Appearance in wordclouds' },
			{ from: 'family', date: 'regularly and consistently', to: 'Usually not shown' },
			{ from: 'work', date: 'regularly, with peaks and troughs', to: 'Shown during busy work times' },
			{ from: 'fun', date: 'irregularly, with peaks and troughs', to: 'Particularly prominent when it appears' }
		]
	},

	/** TAGS **/
	tags: {
		icon: 'hashtag',
		title: 'Hashtags and handles',
		text: <><p>Trove offers several ways to track specific concepts.</p>
			<p>The most flexible of these is <em>#hashtags</em> or <em>@handles</em> (collectively: <em>tags</em>).
				These function similarly to those on social media, except that there is
				no functional difference within Trove between a hashtag and a handle.</p>
			<p>They can be viewed on the Explore page, either in wordclouds or individually.
				Each hashtag and handle is tracked for how prominent it has been over time,
				and which others appear in the same entries as it.</p>
			<p>An important feature of Trove is that once a word or phrase has been used as a hashtag,
				it will be tracked even without the hashtag.
				This allows you to mark interesting words or phrases once — with a single hashtag — and otherwise write normally.
				For multi-word tags this is case sensitive, as shown:</p></>,
		subsections: ['ironLinks'],
		toplevel: true,
		example: [
			{ from: 'Entry text', to: 'Tag' },
			{ from: '#birthday', to: '#birthday' },
			{ from: 'Birthday', to: '#birthday', description: 'The word matches an existing tag, #birthday' },
			{ from: '@Jane', to: '@jane', description: 'Tags are presented in lowercase' },
			{ from: 'Jane', to: '#jane' },
			{ from: '@JanesDad', to: '@janesdad', description: 'The capitalisation indicates that these are three separate words' },
			{ from: "Jane's dad", to: '@janesdad', description: 'The words match the existing tag, @janesdad' },
			{ from: '#whynot', to: '#whynot', description: 'No capitalisation means this is treated as a one-word tag' },
			{ from: 'why not', to: '', description: "With #whynot being treated as one word, it doesn't match these two separate ones" }
		]
	},
	ironLinks: {
		icon: 'link', // or 'handshake'
		title: 'Tag links',
		text: <>
			<p>
				For any given tag, you can view others that appear in the same entries.
				This can give an idea of how closely related the two tags are for you.
			</p>
			<p>In most cases, links are stronger when the words appear many times together anywhere within entries.
				This could mean both appearing a lot within a few entries;
				both appearing a few times within a large number of entries, usually together;
				or both appearing commonly throughout all entries.
			</p>
			<p>
				For stronger, permanent links you can include a <em>!</em> symbol to mark that the given link
				is inherently tied to all others <em>on the same line</em> within the given entry.
				This link is then independent of the amount they occur together, and can only be reset
				by editing the entry to remove the <em>!</em>.
			</p>
		</>,
		exampleConfig: {
			isToResult: true
		},
		example: [
			{ from: 'Entry text', to: 'Result' },
			{ from: 'ate #food with #family', to: "'food' and 'family' will be linked if they co-occur a lot" },
			{ from: '#family time: #food food food', to: "'food' will will be be more strongly linked with 'family'" },
			{ from: 'saw #family: #!dad', to: "'family' and 'dad' will be forever tightly linked" }
		]
	},

	/** NAMES **/
	names: {
		icon: 'signature',
		title: 'Names',
		text: '',
		// toplevel: true,
		subsections: ['countries', 'namesFirsts', 'titles'],
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'John (Smith)', to: 'John Smith' },
			{ from: 'John Smith', to: 'John Smith' },
			{ from: 'Fred Jones' },
			{ from: 'John', to: 'John Smith' },
			{ from: 'john', to: '' },
			{ from: 'Jane (Smith-Jones)', to: 'Jane Smith-Jones' },
			{ from: 'Pierre (de la Ville)', to: 'Pierre de la Ville' },
			{ from: 'jake (smith)', to: '' }
		]
	},
	countries: {
		icon: 'flag',
		title: 'Country codes',
		text: '',
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'JohnGB (Smith)', to: 'John Smith (GB)' },
			{ from: 'JohnUS (Doe)', to: 'John Doe (US)' },
			{ from: 'JohnGB', to: 'John Smith (GB)' },
			{ from: 'John', to: 'John Smith (GB)' },
			{ from: 'JaneGB', to: 'Jane ??? (GB)',
				description: "As GB already matches another name, it's used to assume that Jane is a new person from there." },
			{ from: 'EffieEX', description: 'Neither Effie nor the country EX are recognised, so this is not considered a name.' }
		]
	},
	namesFirsts: {
		icon: 'arrows-left-right-to-line',
		title: 'First-time names',
		text: '',
		example: [
			{ from: 'Entry text', date: 'Date', to: 'Name' },
			{ from: 'John (Smith)', date: 'Day 1', to: 'John Smith' },
			{ from: 'John-1', date: 'Day 2', to: 'John Jones' },
			{ from: 'John (Jones)', date: 'Day 3', to: 'John Jones' },
			{ from: 'John-1', date: 'Day 4', to: 'John ???' }
		]
	},
	titles: {
		icon: 'person-rays',
		title: 'Titles',
		text: 'Note that this only supports specific titles: list is <see config>',
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'Sir Galahad', to: 'Sir Galahad' },
			{ from: 'Galahad', to: 'Sir Galahad' },
			{ from: 'Count BinfaceUK', to: 'Count Binface (UK)', description: 'Country codes are also supported' }
		]
	},

	/** ALIASES **/
	aliases: {
		title: 'Automatic corrections',
		icon: 'screwdriver-wrench',
		text: '',
		// toplevel: true,
		subsections: ['typos', 'nicknames']
	},
	typos: {
		icon: 'strikethrough',
		title: 'Typo detection',
		text: '',
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'John (Smith)', to: 'John Smith' },
			{ from: 'John', to: 'John Smith' },
			{ from: 'Johm', to: 'John Smith' }
		]
	},
	nicknames: {
		icon: 'person-walking-arrow-loop-left', // user-tag or person-walking-arrow-loop-left?
		title: 'Nicknames',
		text: '',
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'Katie (Smith)', to: 'Katie Smith' },
			{ from: 'Katy (Smith)', to: 'Katie Smith' },
			{ from: 'Katrina (Smith)', to: 'Katie Smith' }
		]
	}
}
