import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import EntryContentText from './EntryContentText'
import { EntryContext } from '../contexts/EntryContext'
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faPencil } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../contexts/AppContext'
import { Link } from 'react-router-dom'
import { Entry } from './Entry.d'
import { ENTRY_TYPE_NORMAL, ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS, MAX_ENTRY_PREVIEW } from '../Write/config'
import { formatDate, formatDateEnd, formatNumberWithCommas, formatTimeInterval, parseHms } from '../helpers/format'
import { actionInProgress } from '../helpers/action/action'
import { getEntriesActionKey } from '../Write/actions'
library.add(faPlus, faMinus, faPencil)

const EntryContentStatistic = (props: { icon: IconProp, value: string | number | undefined }): JSX.Element =>
	props.value ? <> <FontAwesomeIcon icon={props.icon} />{props.value} </> : <></>

const EntryContentMetadata = (props: { entry: Entry }): JSX.Element => {
	const { hasLoginScope } = useContext(AppContext)
	return <>
		{ !!(props.entry.title || props.entry.rating || props.entry.energy || props.entry.importance) &&
		<h3>
			{props.entry.title}
			{ props.entry.rating && <EntryContentStatistic icon='star' value={props.entry.rating} />}
			{ props.entry.energy && <EntryContentStatistic icon='bolt' value={props.entry.energy} />}
			{ props.entry.importance && <EntryContentStatistic icon='award' value={props.entry.importance} />}
		</h3>}
		{ props.entry.loc && <EntryContentStatistic icon='map-marker-alt' value={props.entry.loc} />}
		{ props.entry.type === ENTRY_TYPE_NORMAL && (props.entry.startText || props.entry.endText)
			&& <div className='tip'>
				<EntryContentStatistic icon='sun' value={props.entry.startText} />
				{ props.entry.startText && props.entry.endText && ' to '}
				<EntryContentStatistic icon='bed' value={props.entry.endText} />
			</div>}
		{ [ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type || 0) && props.entry.endText && hasLoginScope('write') &&
			<div className='tip'>{
				formatDate(props.entry.date, 'long') } to {formatDateEnd(props.entry.endText, props.entry.date, 'long')
			}</div>}
		{ props.entry.price && <EntryContentStatistic icon='money-bill' value={props.entry.price} />}
	</>
}

const EntryContentWithSummary = (props: { entry: Entry }): JSX.Element => {
	const [showFullEntry, setShowFullEntry] = useState(false)

	return <>
		{ <div className='toggle-summary' onClick={ () => setShowFullEntry(!showFullEntry)}>
			<FontAwesomeIcon icon={showFullEntry ? 'minus' : 'plus'} />
			{showFullEntry ? 'Summarise' : 'Expand'} entry
		</div>}
		{ (!showFullEntry || props.entry.summary) && <EntryContentText entry={props.entry} showSummary={true} />}
		{ showFullEntry && props.entry.summary && <div className='separator-summary' />}
		{ showFullEntry && <EntryContentText entry={props.entry} showSummary={false} />}
	</>
}

const EntryWriteSpeed = (props: { entry: Entry}): JSX.Element => {
	const exactTime = props.entry.writeup_time_exact ? parseHms(props.entry.writeup_time_exact) : 0
	const totalTime = props.entry.writeup_time_total ? parseHms(props.entry.writeup_time_total) : 0
	return <span className='tip'>
		{ !!props.entry.wordcount && <>{formatNumberWithCommas(props.entry.wordcount)} words</>}
		{ !!props.entry.wordcount && !!exactTime && ' in '}
		{ !!exactTime && <span title='Time spent actively typing into the entry.'>{formatTimeInterval(exactTime / 1000) }</span>}
		{ !!props.entry.wordcount && !!exactTime && <span title='Typing speed, in words per minute.'> ({
			Math.round(props.entry.wordcount / exactTime * 100000 * 60) / 100 } wpm)</span>}
		{ !!exactTime && !!totalTime && <span title='Total time the entry was being written, including pauses of up to an hour.'> across {
			formatTimeInterval(totalTime / 1000)}</span>} {}
	</span>
}

const EntryContent = (props: { entryId: number, hasPrev?: boolean }): JSX.Element => {
	const { isUserType } = useContext(AppContext)
	const { state } = useContext(EntryContext)
	const entry = state.write?.[props.entryId] || state.entries?.[props.entryId]
	const hasSummary = !!entry?.summary || (entry?.entry?.length || 0) >= MAX_ENTRY_PREVIEW
	if (!entry)
		return <FontAwesomeIcon className='entry-on-day' icon='spinner' spin={true} />
	return <div className='entry-on-day'>
		{ props.hasPrev && <div className='separator-entry' />}
		<EntryContentMetadata entry={entry} />
		{ hasSummary ? <EntryContentWithSummary entry={entry} /> : <EntryContentText entry={entry} /> }
		{!isUserType('public') && <div>
			{ actionInProgress(getEntriesActionKey(props.entryId), state)
				&& <FontAwesomeIcon className='loading' icon='spinner' spin={true} />}
			<EntryWriteSpeed entry={entry} />
			<Link to={`/write/?entryId=${entry.id}`}>
				<FontAwesomeIcon icon='pencil' /> Edit entry
			</Link></div>}
	</div>

}
EntryContent.propTypes = { entryId: PropTypes.number.isRequired, hasPrev: PropTypes.bool }

export default EntryContent
