import React, { useContext, useState, ChangeEventHandler } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import SettingsIcon from './SettingsIcon'
import { AppContext } from '../contexts/AppContext'
import { library } from '@fortawesome/fontawesome-svg-core'
import { SettingsContext } from '../contexts/SettingsContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useHistory } from 'react-router-dom'
import { faSave, faLink, faUnlink, faHourglassHalf, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { actionStatus, cancelInProgress } from '../helpers/action/action'
import { PrimaryAction } from '../helpers/action/Action.d'
import { AppContextState } from '../contexts/AppContext.d'
import { SettingsAction, SettingsSyncApi } from '../helpers/action/SettingsAction.d'
import {
	BUTTON_REFRESH, EXTERNAL_API_LINK, EXTERNAL_API_UNLINK, STATUS_ICON_FAILED, STATUS_ICON_LOADING
} from '../contexts/SettingsContext.d'
import { formatDate } from '../helpers/format'
library.add(faSave, faLink, faUnlink, faHourglassHalf, faPaperclip)

/** User fields which are set using redirects and such, not manual copy/paste. */
export const AUTO_FIELDS = ['fitbit_user_id', 'fitbit_refresh_token']

const canSync = (api: string, appState: AppContextState, autoOnly = false) =>
	Object.keys(appState.user?.row || {})
		.concat(AUTO_FIELDS.filter(key => !(key in (appState.user?.row || {}))))
		.filter(key => key.includes(`${api}_`))
		.filter(key => !autoOnly || AUTO_FIELDS.includes(key))
		.filter(key => !appState.user?.row?.[key])
		.length === 0

const Sync = (): JSX.Element =>
	<div className='thumbnail thumbnail-static'>
		<h3><FontAwesomeIcon icon='paperclip'/>External data sources</h3>
		<div>
			<h4 className='sync-logo'>
				<a href='https://www.fitbit.com/' target='_blank' rel='noopener noreferrer'>
					<img src='/images/Fitbit_logo_RGB.svg' alt='FitBit' />
					<FontAwesomeIcon className='external-link' icon='external-link-alt' />
				</a>
			</h4>
			<SyncTrigger api='fitbit' />
			<SyncAuth api='fitbit' />
			<div className='sync-input-rows'>
				<SyncInput api='fitbit' field='start_date' text='Gather data from' isDate={true} />
				<SyncInput api='fitbit' field='user_id' text='User ID'
					href='https://community.fitbit.com/t5/Web-API-Development/Where-do-I-find-my-User-ID/td-p/1138653' />
				<SyncInput api='fitbit' field='refresh_token' text='Refresh token'
					href='https://dev.fitbit.com/build/reference/web-api/oauth2/#refreshing-tokens' />
			</div>
		</div>
		<div>
			<h4 className='sync-logo'>
				<a href='https://www.rescuetime.com/' target='_blank' rel='noopener noreferrer'>
					<img src='/images/RescueTime.svg' alt='RescueTime' /> RescueTime
					<FontAwesomeIcon className='external-link' icon='external-link-alt' />
				</a>
			</h4>
			<SyncTrigger api='rescuetime' />
			<div className='sync-input-rows'>
				<SyncInput api='rescuetime' field='start_date' text='Gather data from' isDate={true} />
				<SyncInput api='rescuetime' field='api_key' text='API Key'
					href='https://www.rescuetime.com/anapi/manage' />
			</div>
		</div>
	</div>

const SyncInput = (props: InferProps<typeof syncInputPropTypes>) => {
	const { appState, appDispatch, setShortcutsActive } = useContext(AppContext)
	const field = `${props.api}_${props.field}`
	const baseValue = appState.user?.row?.[field]
	const modifiedValue = appState.user?.updated?.[field]
	const value = String(modifiedValue !== undefined ? modifiedValue : baseValue || '')
	const [dateValue, setDateValue] = useState(formatDate(value) == value ? value : undefined)
	if (AUTO_FIELDS.includes(field)) { return null }
	const saveAction: PrimaryAction = { type: 'SAVE_USER_FIELD', field, value }

	const status = actionStatus(saveAction, appState)
	const statusIcon = typeof status === 'number'
		? 'hourglass-half' : status === 'requestSent' ? 'spinner' : STATUS_ICON_FAILED
	const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		// Cancel any timer to save the previous value
		cancelInProgress(saveAction, appState, appDispatch)
		if (props.isDate) { setDateValue(e.target.value) }
		appDispatch({ type: 'UPDATE_USER_FIELD', field, value: e.target.value })
	}
	return (
		<form
			onSubmit={e => e.preventDefault()}
			onFocus={() => setShortcutsActive(false)}
			onBlur={() => setShortcutsActive(true)}>
			<SettingsIcon icon={statusIcon} />
			{ props.href ? <a target='_blank' rel='noopener noreferrer'
				href={props.href}>{props.text}</a> : props.text}{' '}
			<input disabled={AUTO_FIELDS.includes(field)} type={props.isDate ? 'date' : 'text'}
				value={props.isDate ? dateValue : value}
				onChange={onChange} />
		</form>
	)
}
const syncInputPropTypes = {
	api: PropTypes.string.isRequired,
	field: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	isDate: PropTypes.bool,
	href: PropTypes.string
}
SyncInput.propTypes = syncInputPropTypes

const SyncTrigger = (props: { api: SettingsSyncApi }) => {
	const { appState } = useContext(AppContext)
	const { state: settings } = useContext(SettingsContext)
	// Only show the Trigger if we have all required user fields.
	if (!canSync(props.api, appState)) { return null }
	const action: SettingsAction = { type: 'TRIGGER_SYNC', api: props.api }
	const state = settings.sync?.[ props.api ] || {}
	const status = state.loading ? STATUS_ICON_LOADING :
		JSON.stringify(action) in (settings.failed || {}) ? STATUS_ICON_FAILED : BUTTON_REFRESH
	return (
		<div>
			<SettingsIcon icon={status} action={action} className={'sync-button'} />
			{state.text || 'Retrieve the latest data'}
		</div>)
}
SyncTrigger.propTypes = {
	api: PropTypes.string.isRequired
}

const SyncAuth = (props: { api: SettingsSyncApi }) => {
	const { state: settings } = useContext(SettingsContext)
	const { appState, appDispatch } = useContext(AppContext)
	const redirected = new URLSearchParams(useLocation().search).get('redirected') === props.api
	const history = useHistory()
	const isLinked = canSync(props.api, appState, true)

	if (redirected && isLinked) {
		setTimeout(() => history.push('/settings'), 1)
	}
	const action: SettingsAction = isLinked || props.api != 'fitbit'
		? { type: 'UNLINK_SYNC_API', api: props.api, appDispatch }
		: { type: 'GET_SYNC_LOGIN_URL', api: props.api }
	const state = settings.sync?.[ props.api ] || {}
	const authUrl = settings.sync?.[ props.api ]?.authUrl || null
	const status = state.authenticating || (redirected && !isLinked) ? STATUS_ICON_LOADING
		: JSON.stringify(action) in (settings.failed || {})
			? STATUS_ICON_FAILED
			: isLinked
				? EXTERNAL_API_UNLINK
				: EXTERNAL_API_LINK
	const authenticate = () => window.location.href = `${authUrl}&state=${props.api}_redirected`
	if (!isLinked && authUrl && status === STATUS_ICON_LOADING) { authenticate() }
	return <div>
		<SettingsIcon icon={status} action={action} className={'sync-button'} />
		{isLinked ? 'Unlink your Fitbit account' : 'Link your Fitbit account'}
	</div>
}
SyncAuth.propTypes = {
	api: PropTypes.string.isRequired
}

export default Sync
