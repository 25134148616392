import React, { useContext, useState } from 'react'
import { AppContext } from '../contexts/AppContext'
import { useAuth0 } from '@auth0/auth0-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSlash, faPlaneCircleExclamation, faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContextState } from '../contexts/AppContext.d'
library.add(faUserSlash, faPlaneCircleExclamation, faSquarePlus)

/** Number of seconds to show a short-lived notification, e.g. data version changed */
export const NOTIFICATIONS_SHOWN_FOR = 7

const NavNotificationBarWrapper = (): JSX.Element => {
	const { appState } = useContext(AppContext)
	const { isLoading, isAuthenticated } = useAuth0()
	if (appState.notification?.message) {
		return <NavNotificationBar {...appState.notification} />
	} else if (appState.offlineMode) {
		return <NavNotificationBar message='You appear to be offline.' className='error' icon='plane-circle-exclamation' />
	} else if (!isLoading && !isAuthenticated && !appState.disableAuth) {
		return <NavNotificationBar className='error' icon='user-slash'
			message='An error occurred with your session. You may need to log out and in again.' />
	}
	return <NavNotificationBar />
}

type NotificationProps = AppContextState['notification'] & {className?: string}
const NavNotificationBar = (props: NotificationProps): JSX.Element => {
	/* In order to persist the message while the bar is prettily transitioning down, we cache its previous content */
	const [latestProps, setLatestProps] = useState<NotificationProps | undefined>(undefined)
	if (props.message && props.message !== latestProps?.message) {
		setLatestProps({ message: props.message, className: props.className, icon: props.icon, flipped: props.flipped })
	}
	const currentProps: NotificationProps = {
		message: props.message || latestProps?.message,
		className: `${props.message ? 'nav-notification-bar-shown ' : ''}${props.className || latestProps?.className}`,
		icon: props.icon || latestProps?.icon,
		flipped: props.flipped || latestProps?.flipped
	}
	return <div id='nav-notification-bar' className={currentProps.className}>
		<div id='nav-notification-bar-content'>
			{ currentProps.icon && <FontAwesomeIcon icon={currentProps.icon} flip={currentProps.flipped && 'horizontal'} /> }
			{ currentProps.message }
		</div>
	</div>
}

export default NavNotificationBarWrapper
