import React, { useContext } from 'react'
import { RecentPeopleThumbnailList, TopPeopleThumbnailList } from '../Person/PersonThumbnailList'
import { library } from '@fortawesome/fontawesome-svg-core'
import { AppContext } from '../contexts/AppContext'
import Tabs, { TabSet } from '../common/Tabs'
import { faArrowCircleRight, faCalendarWeek, faClockRotateLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { PersonContext } from '../contexts/PersonContext'
import { formatDate, formatNumberWithCommas, formatTimeInterval } from '../helpers/format'
import { Link } from 'react-router-dom'
import Graph from '../common/Graph'
import { EntryRetriever } from '../reducers/EntryReducer'
import { PersonRetriever } from '../reducers/PersonReducer'
import { EntryContext } from '../contexts/EntryContext'
import { ExploreHelp } from './ExploreHelp'
library.add(faArrowCircleRight, faInfoCircle, faClockRotateLeft, faCalendarWeek)

const RECENT_PEOPLE_CLOUD_DAYS = 30

export type ExploreTab = 'recent' | 'allTime' | 'help'

const exploreTabs: TabSet<ExploreTab> = {
	'recent': {
		icon: 'clock-rotate-left',
		text: 'Recent'
	},
	'allTime': {
		icon: 'calendar-week',
		text: 'All time'
	},
	'help': {
		icon: 'info-circle',
		text: 'Help'
	}
}

export const ExploreUserStats = (props: { from?: number, until?: number}): JSX.Element => {
	const { state } = useContext(EntryContext)
	const stats = state.stats?.[JSON.stringify({ type: 'QUERY_USER_STATS', from: props.from, until: props.until })]
	if (!stats) {
		return <></>
	}
	const entry_ies = stats.entries === 1 ? 'entry' : 'entries'
	const inQuery = props.from && props.until
		? `${entry_ies} in this period`
		: props.from && !props.until
			? `recent ${entry_ies}`
			: !props.from && props.until
				? `${entry_ies} before then`
				: `${entry_ies} in total`
	const prepareNudge = stats.entriesUpdated === 0 ? ''
		: <p className='tip'>Note: entries may have been modified since this was calculated.
			{' '}You can <Link to='/settings'>process them</Link> periodically.</p>
	if (stats.entries === 0) {
		return <div id='explore-user-stats'>
			<p>You have <strong>0</strong> {inQuery}. Why not <Link to='/write'>write one now</Link>?</p>
			{prepareNudge}
		</div>
	}
	return <div id='explore-user-stats'>
		<p>You have <strong>{stats.entries}</strong> {inQuery}.
			{' '}{stats.entries === 1 ? 'It mentions' : 'They mention'} {formatNumberWithCommas(stats.peopleCountUnique as number)}
			{' '}{stats.peopleCountUnique === 1 ? 'person/tag' : 'people/tags'}
			{' '}a total of {formatNumberWithCommas(stats.peopleCountTotal as number)} times.</p>
		<p>You&apos;ve spent a total of {formatTimeInterval(stats.writeupTimeExactTotal as number)} actively writing
			{' '}{formatNumberWithCommas(stats.wordcountTotal as number)} words
			{' '}in {stats.entries === 1 ? 'that entry' : 'those entries'}.</p>
		{ stats.entries > 1 && <p>Per entry, that&apos;s a median of
			{' '}{formatNumberWithCommas(Math.round(stats.wordcountAvg * 10) / 10)} words
			{' '}in {formatTimeInterval(stats.writeupTimeExactAvg as number)}
			{' '}across {formatTimeInterval(stats.writeupTimeTotalAvg as number)},
			{' '}at {Math.round(stats.writeupSpeedAvg * 10) / 10} wpm.</p> }
		{prepareNudge}
	</div>
}

const ExploreAllTime = (): JSX.Element => <>
	<div id='explore-user-stats'>
		<EntryRetriever>
			<ExploreUserStats />
		</EntryRetriever>
	</div>
	<TopPeopleThumbnailList maxItems={5} type='all' />
</>

export const ExplorePage = (): JSX.Element => {
	const { appState, appDispatch } = useContext(AppContext)
	const currentTab = appState.explore?.tab || 'recent'
	const changeTab = (tab: ExploreTab) => appDispatch({ type: 'EXPLORE_SELECT_TAB', tab })
	return <>
		<Tabs tabs={exploreTabs} currentTab={currentTab} changeTab={changeTab} />
		{ currentTab === 'help' && <ExploreHelp /> }
		{ currentTab === 'allTime' && <ExploreAllTime />}
		{ currentTab === 'recent' && <ExploreRecent />}
	</>
}

export const ExploreRecent = (): JSX.Element => {
	const recent = new Date(formatDate()).getTime() / 1000 - RECENT_PEOPLE_CLOUD_DAYS * 60 * 60 * 24
	return <>
		<h3>Since {formatDate(recent * 1000, 'long')}:</h3>
		<div id='explore-user-stats'>
			<EntryRetriever>
				<ExploreUserStats from={recent * 1000} until={undefined} />
			</EntryRetriever>
		</div>
		<RecentPeopleThumbnailList date={recent} maxItems={3} />
		<div>
			<PersonRetriever>
				<Graph context={PersonContext} action={{
					type: 'GRAPH_WORDS_CLOUD_BETWEEN_DATES',
					start: recent
				}}/>
			</PersonRetriever>
			<PersonRetriever>
				<Graph context={PersonContext} action={{
					type: 'GRAPH_PEOPLE_CLOUD_BETWEEN_DATES',
					start: recent
				}}/>
			</PersonRetriever>
		</div>
	</>
}

export default ExplorePage
