import { Reducer } from 'react'
import { treatLocalAction } from '../helpers/context'
import { AUTO_FIELDS } from '../Settings/Sync'
import { CheckPrepareStatusApiResponse, SettingsContextState, CheckInstanceStatusApiResponse,
	STATUS_PREPARE_PENDING, STATUS_ICON_WORKING, INSTANCE_CONTROL_STARTED,
	INSTANCE_CONTROL_STOPPED, STATUS_ICON_FAILED, STATUS_ICON_SUCCESS, INSTANCE_CONTROL_STARTING,
	INSTANCE_CONTROL_STOPPING } from '../contexts/SettingsContext.d'
import { AnyAction, ApiResponseAction, PrimaryAction } from '../helpers/action/Action.d'
import { SuperDispatch } from '../helpers/dispatch.d'
import { actionTypeChangedException } from '../helpers/action/action'

export const SettingsReducer: Reducer<SettingsContextState, AnyAction> = (state, action): SettingsContextState =>
	'apiResponse' in action
		? SettingsReducerApiResponse(state, action)
		: SettingsReducerLocal(state, action)

const SettingsReducerLocal: Reducer<SettingsContextState, PrimaryAction> = (state, action) => {
	switch (action.type) {

	case 'SYNC_CALL_INITIATED':
		return { ...state,
			sync: {
				...state.sync,
				[action.api]: {
					[action.request === 'authUrl' ? 'authenticating' : 'loading']: true,
					text: action.request === 'authUrl'
						? 'Please wait...' : 'Synchronising...',
					authUrl: undefined
				}
			}
		}
	case 'SET_WINDOW_FOCUSED':
		return {
			...state,
			windowFocused: action.focused
		}

	case 'RESET_INSTANCE_STATUS':
		delete state.instances?.[action.instanceId]
		return { ...state }
	default:
		return treatLocalAction(state, action)

	}
}

// This function governs actions which update local state.
export const SettingsReducerApiResponse = (state: SettingsContextState, action: ApiResponseAction): SettingsContextState => {

	if (action.type == 'API_REQUEST_FAILED') {
		if (action.sourceAction.type === 'TRIGGER_SYNC') {
			state.sync = state.sync || {}
			try {
				state.sync[action.sourceAction.api] = {
					loading: false,
					text: JSON.parse(action.responseText || '')?.error
				}
			} catch(unusedError) {
				state.sync[action.sourceAction.api] = {
					loading: false,
					text: action.responseText + ' - try again'
				}
			}
		}
		return treatLocalAction(state, action)
	} else if (action.type === 'API_REQUEST_SUCCEEDED') {
		return treatLocalAction(state, action)
	}

	switch (action.sourceAction.type) {
	case 'CHECK_INSTANCE_STATE':
		return updateInstanceState(state, action as unknown as CheckInstanceStatusApiResponse)

	case 'TRIGGER_PREPARE':
		state.instances[action.sourceAction.instanceId] = STATUS_PREPARE_PENDING
		action.redispatch({ type: 'CHECK_PREPARE_STATUS', instanceId: 'prepare' })
		return { ...state }

	case 'CHECK_PREPARE_STATUS':
		state.instances[action.sourceAction.instanceId] =
			((action as CheckPrepareStatusApiResponse).apiResponse.data?.runningTasks || [])?.length > 0 ? STATUS_PREPARE_PENDING
				:	((action as CheckPrepareStatusApiResponse).apiResponse.data?.latestStoppedTask?.failed ||
					(action as CheckPrepareStatusApiResponse).apiResponse.data?.latestCompleteLog?.failed
					? STATUS_ICON_FAILED : STATUS_ICON_SUCCESS)
		return { ...state, prepare: (action as CheckPrepareStatusApiResponse).apiResponse.data }

	case 'TURN_INSTANCE_ON':
	case 'TURN_INSTANCE_OFF':
		state.instances[action.sourceAction.instanceId] =
			typeof action.apiResponse?.data === 'string' && action.apiResponse.data.includes('No action performed')
				? (action.type === 'TURN_INSTANCE_ON' ? INSTANCE_CONTROL_STARTED : INSTANCE_CONTROL_STOPPED)
				: (action.type === 'TURN_INSTANCE_ON' ? INSTANCE_CONTROL_STARTING : INSTANCE_CONTROL_STOPPING)
		return { ...state }

	case 'GET_SYNC_LOGIN_URL':
		return {
			...state,
			sync: {
				...state.sync,
				[action.sourceAction.api]: {
					...state.sync?.[action.sourceAction.api],
					authUrl: String(action.apiResponse?.data)
				}
			}
		}

	case 'SYNC_FITBIT_AUTH_CALLBACK':
		setTimeout(() => {
			if (action.sourceAction.type !== 'SYNC_FITBIT_AUTH_CALLBACK')
				throw actionTypeChangedException(action)
			action.sourceAction.appDispatch({
				type: 'SAVE_USER_FIELD',
				field: `${(action.sourceAction).api}_refresh_token`,
				value: action.apiResponse?.data?.[`${action.sourceAction.api}_refresh_token`] })
			action.sourceAction.appDispatch({
				type: 'SAVE_USER_FIELD',
				field: `${action.sourceAction.api}_user_id`,
				value: action.apiResponse?.data?.[`${action.sourceAction.api}_user_id`] })
		}, 1)
		return {
			...state,
			sync: {
				...state.sync,
				[action.sourceAction.api]: {}
			}
		}

	case 'UNLINK_SYNC_API':
		setTimeout(() => {
			const { sourceAction } = action
			if (sourceAction.type !== 'UNLINK_SYNC_API')
				throw actionTypeChangedException(action)
			AUTO_FIELDS.filter(field => field.includes(`${sourceAction.api}_`))
				.forEach((field) => sourceAction.appDispatch(
					{ type: 'SAVE_USER_FIELD', field, value: undefined }
				))
		}, 1)
		return state

	case 'TRIGGER_SYNC':
		return { ...state,
			sync: {
				...state.sync,
				[action.sourceAction.api]: {
					loading: false,
					text: String(action.apiResponse?.data)
				}
			} }

	default:
		return treatLocalAction(state, action)
	}
}

const updateInstanceState: Reducer<SettingsContextState, CheckInstanceStatusApiResponse>  = (state, action) => {
	if (!('instanceId' in action.sourceAction)) {
		return state
	}
	state.instances = state.instances || {}
	const stateInfo = action.apiResponse.data.id
		? [action.apiResponse.data]
		: Object.values(action.apiResponse.data)
	const instanceState = stateInfo.every(r => r.isRunning)
		? INSTANCE_CONTROL_STARTED
		: stateInfo.every(r => r.isStopped)
			? INSTANCE_CONTROL_STOPPED
			: stateInfo.every(r => r.isStarting)
				? INSTANCE_CONTROL_STARTING
				: stateInfo.every(r => r.isStopping)
					? INSTANCE_CONTROL_STOPPING
					: STATUS_ICON_WORKING

	state.instances[action.sourceAction.instanceId] = instanceState
	return state
}

// This function governs API-calling actions. They may later end up above.
export const SettingsDispatcher: SuperDispatch = (action, dispatch, dispatchToApi) => {
	switch (action.type) {
	case 'CHECK_INSTANCE_STATE':
		return dispatchToApi('admin/instance/' + action.instanceId, { method: 'GET' })

	case 'TURN_INSTANCE_ON':
		return dispatchToApi('admin/instance/' + action.instanceId, { method: 'POST', body: { action: 'start' } })

	case 'TURN_INSTANCE_OFF':
		return dispatchToApi('admin/instance/' + action.instanceId, { method: 'POST', body: { action: 'stop' } })

	case 'CHECK_PREPARE_STATUS':
		return dispatchToApi('general/prepare', { method: 'GET' })

	case 'TRIGGER_PREPARE':
		return dispatchToApi('general/prepare', { method: 'POST' })

	case 'TRIGGER_SYNC':
		dispatch({ type: 'SYNC_CALL_INITIATED', api: action.api })
		return dispatchToApi(`sync/${action.api}`, { method: 'POST' })

	case 'GET_SYNC_LOGIN_URL':
		dispatch({ type: 'SYNC_CALL_INITIATED', api: action.api, request: 'authUrl' })
		return dispatchToApi(`sync/${action.api}/auth`, { method: 'GET' })

	case 'SYNC_FITBIT_AUTH_CALLBACK':
		return dispatchToApi(`sync/${action.api}/auth?code=${action.code}`, { method: 'POST' })

	case 'UNLINK_SYNC_API':
		return dispatchToApi(`sync/${action.api}/auth`, { method: 'DELETE' })

	default:
		return dispatch(action)
	}
}
